<template>
  <div class="lg:flex lg:flex-row flex-col items-center justify-around gap-[34px] p-[50px] bg-[#ECECEC]">
    <div class="flex flex-col justify-center items-center leading-normal lg:mb-0 mb-10">
      <span class="lg:text-[45px] text-[30px] leading-45 font-montserrat font-medium capitalize break-words">Canopy Roofing</span>
      <span class="lg:text-[45px] text-[30px] leading-45 font-montserrat font-medium capitalize break-words">Certified By</span>
    </div>

    <div class="xl:flex xl:flex-wrap grid grid-cols-1 lg:grid-cols-2 items-center space-x-4 justify-center lg:gap-[80px] gap-[50px]">
      <div v-for="(image, index) in images" :key="index" class="flex items-center xl:justify-normal justify-center relative">
        <img :src="image.src" :alt="image.alt" class="h-auto w-auto max-w-full object-contain">
        <!-- Vertical line divider (if not last item) -->
        <div v-if="index < images.length - 1" class="divider hidden md:block" />
      </div>
    </div>
  </div>
</template>

<script setup>
const images = [
  { src: '/logos/certificates/gaf.png', alt: 'GAF' },
  { src: '/logos/certificates/garca.png', alt: 'GARCA' },
  { src: '/logos/certificates/national-roofing.png', alt: 'National Roofing' },
  { src: '/logos/certificates/bbb.png', alt: 'BBB' }

]
</script>

<style scoped>
.divider::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  right: -50px;
  top: 0;
}
</style>
